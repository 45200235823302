<template>
  <div>
    <!-- banner -->
    <section class="banner">
      <img src="@/assets/img/bg-data@2x.png" alt>
      <div class="flex-center-column desc">
        <h1>个性定制</h1>
        <p>大数据专家针对您的投研需求，挖掘、处理清洗、分析数据，建立数据库，形成可视化效果等优质服务</p>
        <!-- <div class="button">立即定制</div> -->
      </div>
    </section>
    <!-- banner end -->

    <!-- breadcrumb-->
    <base-breadcrumb :crumbs="items" class="c-m-r c-m-l" />
    <!-- breadcrumb end -->

    <!-- nav -->
    <section class="my-nav c-m-r c-m-l">
      <div class="flex-between">
        <h2 class="title">数据种类</h2>
        <div class="nav-select flex-center" @click="slideToggleTrans">
          展开
          <i v-show="navSlided" class="icon-up-down icon-up" />
          <i v-show="!navSlided" class="icon-up-down icon-down" />
          <!-- <img src="@/assets/img/icon-nav-up.png" v-bind:class="[!navSlided? 'my-display-none' : 'my-display-block']" alt>
          <img src="@/assets/img/icon-nav-down.png" v-bind:class="[navSlided? 'my-display-none' : 'my-display-block']" alt> -->
        </div>
      </div>
      <div id="more" class="flex-common nav-fold-area">
        <div
          v-for="(item, index) in navCategory"
          :key="index"
          class="flex-center nav-item"
          :class="{active: isActived == item.Label}"
          @click="fetchCategoryData(item.Label, item.Category)"
        >
          <img :src="&quot;@/assets/img/icon-nav-&quot; + item.Label +&quot;.png&quot;" class="not-hover" alt>
          <img :src="&quot;@/assets/img/icon-nav-hover-&quot; + item.Label + &quot;.png&quot;" class="hover" alt>
          {{ item.Category }}
        </div>

      </div>
    </section>
    <!-- nav end -->

    <!-- data list -->
    <section class="c-m-r c-m-l mt-30">
      <h2 class="title">
        <!-- {{ isActived }} -->
        <img v-if="isActived!==undefined" :src="&quot;@/assets/img/icon-nav-&quot; + isActived +&quot;.png&quot;" class="not-hover" width="28" height="28" alt>
        {{ navCurrentName }}</h2>
      <div class="flex-common data-list">
        <div
          v-for="(item, index) in categoryItems"
          :key="index"
          class="item"
        >
          <h3>{{ item.Item }}</h3>
          <p>{{ item.Details }}</p>
        </div>

      </div>
    </section>
    <!-- data list end -->
  </div>
</template>

<script>
import '../../assets/style/common.scss'

export default {
  data() {
    return {
      items: [
        {
          text: '主页',
          route: '/#home'
        },
        {
          text: '数据商城',
          route: ''
        }
      ],

      displayNav: false,
      navCategory: [],
      categoryItems: [],
      navSlided: false,
      navCurrentName: '电商数据',
      isActived: 1

    }
  },

  mounted() {
    this.fetchNavData()
    this.fetchCategoryData(1, '电商数据')
  },

  methods: {
    // slide nav
    slideToggleTrans: function() {
      //  display表示默认更多展开元素是显示状态还是隐藏
      var display = false
      var moreEle = document.getElementById('more')
      display = !this.displayNav
      moreEle.style.height = display ? '470px' : '130px'
      this.displayNav = display

      this.navSlided = !this.navSlided
      console.log('this.navSlided', this.navSlided)
    },

    // fetch nav category
    fetchNavData: function() {
      this.http
        .get('api/office_web/data_mail_title/')
        .then(res => {
          this.navCategory = res.data.title
        })
    },

    // fetch category items data
    fetchCategoryData: function(category, navCurrentName, navNum) {
      const api = 'api/office_web/data_mail_content/?label=' + category
      this.http.get(api)
        .then(res => {
          this.categoryItems = res.data.content
        })
      this.navCurrentName = navCurrentName
      this.isActived = category
      console.log('category-isActived', this.isActived)
    }
  }
}
</script>

<style lang="scss">
// *{
//   padding: 0;
//   margin: 0;
// }

.banner {
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  div.desc {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    text-align: center;
    color: #fff;

    h1 {
      font-size: 48px;
    }
    p {
      font-size: 18px;
      margin-top: 10px;
    }
    .button {
      margin-top: 30px;
      padding: 5px 30px;
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 6px;
      font-size: 16px;
      cursor: pointer;
    }
    .button:hover {
      border: 1px solid #ec3c2e;
      color: #ec3c2e;
      opacity: 0.8;
    }
  }
}

h2.title {
  font-size: 26px;
  margin: 0;
}
.my-nav {
  border: 1px solid rgba(229, 229, 229, 1);
  padding: 20px;

  .nav-select {
    padding: 5px 20px;
    border: 1px solid #ec3c2e;

    color: #ec3c2e;
    font-size: 16px;
    cursor: pointer;

    img {
      margin-left: 10px;
    }
  }
  .nav-select:hover {
    // padding: 5px 30px;

    color: #ec3c2e;
    opacity: 0.8;
    font-size: 16px;
    border: 1px solid #ec3c2e;
  }
  .nav-fold-area {
    height: 130px;
    position: relative;
    overflow: hidden;
    transition: 0.6s;
    flex-wrap: wrap;
    margin-top: 38px;

    .nav-item {
      // padding: 10px 65px;
      width: 260px;
      height: 50px;
      background: rgba(238, 238, 238, 1);
      margin-right: 24px;
      margin-bottom: 20px;

      img {
        margin-right: 5px;
      }
      img.not-hover {
        display: block;
      }
      img.hover {
        display: none;
      }
    }

    .nav-item:hover {
      color: #fff;
      background-color: #ec3c2e;
      cursor: pointer;

      img.not-hover {
        display: none;
      }
      img.hover {
        display: block;
      }
    }
    .active {
      color: #fff;
      background-color: #ec3c2e;

      img.not-hover {
        display: none;
      }
      img.hover {
        display: block;
      }
    }
  }
  //  不适用于屏幕变化
  // div.nav-item:nth-child(4n) {
  //     margin-right: 0;
  //   }
}

.data-list {
  margin-top: 38px;
  flex-wrap: wrap;

  .item {
    width: 283px;
    height: 163px;
    padding: 20px 14px;
    border: 1px solid rgba(229, 229, 229, 1);
    border-top: 4px solid #ccccccff;
    box-sizing: border-box;
    letter-spacing: 2px;
    margin-right: 24px;
    margin-bottom: 30px;
    overflow: hidden;

    h3 {
      font-size: 18px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      margin: 0;
    }

    p {
      font-size: 14px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(102, 102, 102, 1);
      margin: 0;
      margin-top: 20px;
    }
  }

  .item:hover {
    border: 1px solid #ec3c2e;
    border-top: 4px solid #ec3c2e;
  }
  //  不适用于屏幕变化
  //  .item:nth-child(4n) {
  //     margin-right: 0;
  //   }
}

.my-display-none {
  display: none !important;
}
.my-display-block {
  display: block !important;
}

//box-shadow make icon
.icon-up-down{display:inline-block; width:20px; height:20px; }
:root .icon-up{background-image:none;}
.icon-up::before{
    content: ''; position: absolute; margin: 5px 0 0 9px;
    height: 3px; border-left: 1px solid #ec3c2e;
    box-shadow: 1px 1px #ec3c2e, 2px 2px #ec3c2e, 3px 3px #ec3c2e, 4px 4px #ec3c2e, 5px 5px #ec3c2e,
               -1px 1px #ec3c2e, -2px 2px #ec3c2e, -3px 3px #ec3c2e, -4px 4px #ec3c2e, -5px 5px #ec3c2e;
}
.icon-down::before{
    content: ''; position: absolute; margin: 10px 0 0 9px;
    height: 3px; border-left: 1px solid #ec3c2e;
    box-shadow: 1px -1px #ec3c2e, 2px -2px #ec3c2e, 3px -3px #ec3c2e, 4px -4px #ec3c2e, 5px -5px #ec3c2e,
               -1px -1px #ec3c2e, -2px -2px #ec3c2e, -3px -3px #ec3c2e, -4px -4px #ec3c2e, -5px -5px #ec3c2e;
}
</style>
